export const AuthSignInPath = "/sign-in";
export const AuthForgotPasswordPath = "/forgot-password";
export const AuthResetPasswordPath = "/reset-password";
export const AuthCheckTotpPath = "/check-totp";
export const AuthSetupTotpPath = "/setup-totp";

export const AuthSuccessRedirectUrl = "/";

export const AuthApiSetupTotpState = "/auth-api/setup-totp-state";
export const AuthApiSetupTotp = "/auth-api/setup-totp";
export const AuthApiCheckTotp = "/auth-api/check-totp";
export const AuthApiSignIn = "/auth-api/sign-in";

export const AuthApiStartResetPassword = "/auth-api/start-reset-password";
export const AuthApiCheckResetPasswordCode = "/auth-api/check-reset-code";
export const AuthApiResetPassword = "/auth-api/reset-password";