export const dateToString: (date: Date | null) => string = (date) => {
    if (!date || !(date instanceof Date)) return "";
    return `${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}-${date.getDate() < 9 ? "0" : ""}${date.getDate()}`;
};

export const dateFromStringOrNow: (strValue: string | null | undefined) => Date = (strValue) => {
    const date = dateFromStringOrNull(strValue);
    return date ?? new Date();
}

export const dateFromStringOrNull: (strValue: string | null | undefined) => Date | null = (strValue) => {
    //if (!strValue || !(typeof strValue === "string")) return null;

    const regex = /^(20\d\d)-([0-1][1-9])-([0-3][0-9])/g;
    const match = regex.exec(strValue!);
    if (!match) return null;

    const year = parseInt(match[1]);
    const month = parseInt(match[2])-1;
    const day = parseInt(match[3]);


    return new Date(year, month, day);


}