/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import moment from "moment";
import Datepicker from "react-datepicker"
import React, { Fragment, ReactElement, useEffect, useMemo, useState } from "react";
import { DeepMap, FieldError, FieldErrors, FieldValues, Path, UseFormReturn, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { ClipboardCopyIcon } from '@heroicons/react/solid'
import { KeyValuePair, ListContainer, NameGuidPair } from "./stdlib-models";
import { FormLabelClass } from "./stdlib-styles";

import "react-datepicker/dist/react-datepicker.css";
import { ActionItem } from "./stdlib-action";
import { RenderActionItem } from "./stdlib-ui";

import { startTransition } from 'react';
import { ObjectValidation, PropertyValidation } from "./stdlib-newform";
import { arrayNotNullOrEmpty } from "./stdlib-funcs";

export enum ModalStatus {
    Idle,
    Network,
    Done,
    Error,
    NetworkError
}


export enum PageState {
    NotLoaded,
    Loaded,
    NotFound,
    Saved,
    NeedAuth,
    Forbidden
}

export enum PageStatus {
    Idle,
    Network,
    ServerError,
    NetworkError
}


export interface ValidationMessage {
    name: string,
    message: string
}

// export interface IValidationResult<T> {
//     fields: { name: keyof T, message: string }[]
// }


const EmptyContainer: <T>(container: ListContainer<T> | undefined) => boolean = (container) => container === undefined || !container.items || container.items.length === 0;

export const EmptyBladeListMessage: <T>(container: ListContainer<T> | undefined) => JSX.Element = (container) => EmptyContainer(container) ? <div className="blade-empty">Empty</div> : <></>;

export const LoadingAnimation = () => (<svg fill='none' className="w-8 h-8 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
    <path clipRule='evenodd'
        d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
        fill='currentColor' fillRule='evenodd' />
</svg>);

export const LoadingView = (
    <div className="flex m-2 gap-2 text-gray-500">
        <LoadingAnimation />
        <div>Loading ...</div>
    </div>
)

export const LoadingView2 = (
    <div className="flex gap-4 text-gray-500">
        <LoadingAnimation />
        <div>Loading ...</div>
    </div>
)

export const apiBannerHelper = (pageState: PageState,
    pageStatus: PageStatus,
    errors: ValidationMessage[]) => {

    const errorMessages: string[] = [];
    let hasErrors = false;

    if (pageState === PageState.NeedAuth) {
        //const returnUrl = `${encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)}`;
        //return <Redirect to={"sign-in" + returnUrl ? `/sign-in?returnUrl=${returnUrl}` : ""} />
        hasErrors = true;
    }

    if (pageStatus === PageStatus.Network) {
        //return LoadingView2;
        hasErrors = true;
    }



    if (pageState === PageState.NotFound) {
        errorMessages.push("Not found");
    } else if (pageState === PageState.Forbidden) {
        errorMessages.push("Forbidden - you do not have permission to view this page");
    } else if (pageStatus === PageStatus.ServerError) {
        errorMessages.push("Server error");
    } else if (pageStatus === PageStatus.NetworkError) {
        errorMessages.push("Network error - check your internet connection");
    } else if (Array.isArray(errors) && errors.length > 0) {
        errorMessages.push(...errors.map(x => `${x.name}: ${x.message}`));
    }

    hasErrors = errorMessages.length > 0;





    const apiStatusBanner = () => {

        if (!hasErrors) return <></>;

        if (pageState === PageState.NeedAuth) {
            const returnUrl = `${encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)}`;
            return <Redirect to={"sign-in" + returnUrl ? `/sign-in?returnUrl=${returnUrl}` : ""} />
        }

        if (pageStatus === PageStatus.Network) {
            return LoadingView2;
        }

        return <div className="mb-2 p-2 bg-red-100 text-red-600">{errorMessages.map((x, i) => <span key={i}>{x}</span>)}</div>;
    }

    return {
        hasBannerMessage: hasErrors,
        apiStatusBanner,
        working: pageStatus === PageStatus.Network
    }
}


export const bannerHelper: (pageState: PageState, pageStatus: PageStatus, options?: { hideLoading?: boolean, redirectUrl?: string, noMargin?: boolean }) => {
    contentNotLoaded: () => boolean,
    allErrorsBanner: (errors: ValidationMessage[], options?: { hideNames: boolean }) => JSX.Element,
    statusBanner: (errors?: ValidationMessage[]) => JSX.Element,
    //singleErrorMessage: <T>(property: keyof T, errors: ValidationMessage[]) => JSX.Element
} = (pageState, pageStatus, options) => {
    const Banner: (props: { children: string | JSX.Element[] }) => JSX.Element = (props) => {
        const padClass = options?.noMargin ? "" : "py-8";
        return (<div className={padClass}><div className="mb-2 p-2 bg-red-100 text-red-600">{props.children}</div></div>);
    };

    const statusBanner = (errors?: ValidationMessage[]) => {

        if (pageStatus === PageStatus.Network) {
            return options && options.hideLoading ? <></> : LoadingView;
        }

        if (pageState === PageState.NeedAuth) {
            const returnUrl = `${encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)}`;
            return <Redirect to={"sign-in" + returnUrl ? `/sign-in?returnUrl=${returnUrl}` : ""} />
            //window.location = `/sign-in?returnUrl=${returnUrl}` as any;
            return <></>;
        }

        if (errors && errors.length > 0) {
            return <Banner>{errors.map((x, i) => <div key={i}>{x.name ? x.name + ": " : ""}{x.message}</div>)}</Banner>
        }

        if (pageState === PageState.NotFound) {
            return <Banner>Not found</Banner>;
        }

        if (pageState === PageState.Saved && options && options.redirectUrl) {
            return <Redirect to={options.redirectUrl} />;
        }

        if (pageState === PageState.Forbidden) {
            return <Banner>Forbidden - you do not have permission to view this page</Banner>;
        }

        if (pageStatus === PageStatus.ServerError) {
            return <Banner>Server error</Banner>;
        }

        if (pageStatus === PageStatus.NetworkError) {
            return <Banner>Network error - check your internet connection</Banner>;
        }

        if (pageState === PageState.Saved && !options?.redirectUrl) {
            return (<div className="py-8"><div className="mb-2 p-2 bg-green-100 text-green-600">Changes saved</div></div>);
        }

        return <></>;

    }



    return {
        contentNotLoaded: () => pageState !== PageState.Saved && pageState !== PageState.Loaded,
        allErrorsBanner: (errors, opts) => {
            if (!Array.isArray(errors) || errors.length === 0) return <></>;
            const children = errors?.map(x => {
                const message = x.name && !opts?.hideNames ? `${x.name}: ${x.message}` : x.message;
                return (<div key="message">{message}</div>);
            })
            return Banner({ children });
        },
        statusBanner,
        //singleErrorMessage
    };
};

export interface formRowProps<T> {
    key?: string
    property: Path<T>
    required?: boolean
    title: string
    details?: string,
    hideTitle?: boolean,
    actionItem?: ActionItem
    //actionButtonTitle?: string,
    //actionButtonAction?: () => void
}

export interface fileInputRowProps<T> extends formRowProps<T> {
    accept?: string
}

export interface textBoxProps<T> extends formRowProps<T> {
    placeholder?: string
    type?: "text" | "tel" | "email" | "password"// | "date" //string
}

export interface selectBoxProps<T> extends formRowProps<T> {
    options: (KeyValuePair & { disabled?: boolean })[] | (NameGuidPair & { disabled?: boolean })[]
}

export interface dataSelectBoxProps<T> extends formRowProps<T> {
    //options: KeyValuePair[] | NameGuidPair[]
    dataParam: string;
    dataUrlFunc: (param: string) => string; //KeyValuePair[] | NameGuidPair[]
}

export enum TextAreaHeight { h256, h128 }

export interface textAreaProps<T> extends textBoxProps<T> {
    rows?: number
    height?: TextAreaHeight
}

export interface checkBoxRowProps {
    title: string
    children: ReactElement<any, any>[] | ReactElement<any, any>
}

export interface checkBoxListProps<T> {
    title: string
    options: KeyValuePair[] | NameGuidPair[]
    property: Path<T>
}



export const FormTextRow: (props: { title: string, message: any, details?: string, key?: string, defaultMessage?: string }) => JSX.Element = (props) => {
    const message = props.message ?? props.defaultMessage;

    return <FormGroup title={props.title}>
        <div>{message as any}</div>
        {props.details && <div key={props.key} className="text-sm">{props.details}</div> as any}
    </FormGroup>
}


export const FormSubheaderRow: (props: { title: string, button?: any }) => JSX.Element = props => <div className="flex flex-grow-1 flex-wrap items-center justify-between  border-t border-b py-2" >
    <div className={`${FormLabelClass}`}>
        {props.title}
    </div>
    {props.button &&
        <div>
            {props.button}
        </div>}
</div>;


export const FormLinkRow: (props: { title: string, url: string }) => JSX.Element = (props) => {

    const linkGroup = <div className="flex gap-4 items-center">
        <a className="link" href={props.url} rel="noreferrer" target="_blank">{props.url}</a>
        <button type="button" onClick={async () => await navigator.clipboard?.writeText(props.url)} className="button-sm inline-flex gap-2"><ClipboardCopyIcon className="h-5 w-5" /> <span>Copy</span></button>

    </div>;


    //return FormTextRow({ title: props.title, message: linkGroup });

    return <FormTextRow title={props.title} message={linkGroup} />
}




export const FormGroup: (props: { title: string, hideTitle?: boolean, for?: string | undefined, children: React.ReactElement<any, any> | React.ReactElement<any, any>[] }) => ReactElement<any, any> = (props) => {
    return (<div className="flex flex-col gap-2 flex-1">
        {!props.hideTitle && <label className={FormLabelClass} htmlFor={props.for}>{props.title}</label>}
        {props.children}
    </div>);
};

export const ValidationMessageFor = (errors: ValidationMessage[], property: string) => {


    if (errors && errors.length > 0) {
        const message = errors.find(x => x.name === property);
        if (message) {
            return ErrorMessageWithText(message.message);
        }
    }

    return <> </>;
}

export const ErrorMessageWithText: (message: string, key?: string) => JSX.Element = (message, key) => <div key={key} className="mb-2 p-2 bg-red-100 text-red-600">{message}</div>;

export const ValidationErrorMessage: <T extends FieldValues>(props: { property: Path<T>, errors: DeepMap<T, FieldError> | FieldErrors<T> }) => JSX.Element = (props) => {
    const getError: (errors: any, property: any) => string = () => {
        const key = props.property as string;
        const errorArray = props.errors as Record<string, FieldError>;
        let errorMessage: string = "";
        if (key.includes(".")) {
            const div = key.indexOf(".");
            const objectName = key.substring(0, div);
            const fieldName = key.substring(div + 1);
            if (errorArray[objectName]) {
                const errorObj = (errorArray[objectName] as any) as Record<string, FieldError>;
                if (errorObj[fieldName]) {
                    if (errorObj[fieldName].message) {
                        errorMessage = errorObj[fieldName].message as string;
                    } else if (errorObj[fieldName].type === "required") {
                        errorMessage = "This field is required";
                    }
                }
            }
        } else if (errorArray[key]) {

            if (errorArray[key].message) {
                errorMessage = errorArray[key].message as string;
            } else if (errorArray[key].type === "required") {
                errorMessage = "This field is required";
            }
        }
        return errorMessage;
    }

    const errorMsg = getError(props.errors, props.property)
    return errorMsg ? <div className="mb-2 p-2 bg-red-100 text-red-600">{errorMsg}</div> : <></>;
};

// export const FormGroupWithErrors: <T extends FieldValues>(
//     props: {
//         title: string,
//         hideTitle?: boolean,
//         details?: string,
//         property: Path<T>,
//         errors: DeepMap<T, FieldError> | FieldErrors<T>,
//         children: React.ReactElement<any, any>,
//         actionTitle?: string,
//         actionFunc?: ()=>void,
//     }) => ReactElement<any, any> = (props) => {
//         return (<FormGroup title={props.title} for={props.title} hideTitle={props.hideTitle}>
//             <div className="flex gap-1">
//                 {props.children}
//                 {props.actionTitle && <button className="btn btn-sm" onClick={props.actionFunc}>{ props.actionTitle}</button>}
//             </div>
//             {props.details ? <div className={FormLabelClass}>{props.details}</div> : <></>}
//             {ValidationErrorMessage({ property: props.property, errors: props.errors })}
//         </FormGroup>);
//     };


export const FormGroupWithErrors: <T extends FieldValues>(
    props: {
        rowProps: formRowProps<T>,
        //title: string,
        //hideTitle?: boolean,
        //details?: string,
        //property: Path<T>,
        errors: DeepMap<T, FieldError> | FieldErrors<T>,
        children: React.ReactElement<any, any>,
        //actionTitle?: string,
        //actionFunc?: () => void,
    }) => ReactElement<any, any> = (props) => {
        return (<FormGroup title={props.rowProps.title} for={props.rowProps.title} hideTitle={props.rowProps.hideTitle}>
            <div className="flex gap-1 flex-1">
                {props.children}
                {props.rowProps.actionItem && RenderActionItem(props.rowProps.actionItem, 0)}
            </div>
            {props.rowProps.details ? <div className={FormLabelClass}>{props.rowProps.details}</div> : <></>}
            {ValidationErrorMessage({ property: props.rowProps.property, errors: props.errors })}
        </FormGroup>);
    };

export interface datePickerProps<T> {
    checkInProperty: keyof T
    checkOutProperty: keyof T
    unavailableDates?: string[]
    title: string
}

export interface DatePickerFormRowElement<T> {
    DatePickerRow: (props: datePickerProps<T>) => ReactElement<any, any>
}

export interface ListTextBoxRowProps<T extends FieldValues> {
    title: string;
    inputType: string,
    pattern?: string,
    property: Path<T>,
    working: boolean,
    watch: UseFormWatch<T>,
    setValue: UseFormSetValue<T>
    errors: DeepMap<T, FieldError>,
    clearErrors: (name?: string | string[]) => void
}

export const ListTextBoxRow: <T extends FieldValues>(props: ListTextBoxRowProps<T>) => JSX.Element = props => {

    const values = (props.watch(props.property) as [] ?? []) as string[];

    const formId = props.property + "addbox";

    const empty = !Array.isArray(values) || values.length === 0;

    const addAction = () => {

        const newValue = (document.getElementById(formId) as any).value as string;
        (document.getElementById(formId) as any).value = "";
        if (!newValue) return;
        if (!empty && values.indexOf(newValue as any) >= 0) return;
        props.clearErrors(props.property);
        props.setValue(props.property, [newValue, ...values] as any, { shouldValidate: true, shouldDirty: true })
    };

    const deleteAction = (item: string) => {
        if (empty) return;
        const newArray = values.filter(x => x !== item);
        props.clearErrors(props.property);
        props.setValue(props.property, newArray as any, { shouldValidate: true, shouldDirty: true })
    }

    return <div className="flex flex-col gap-2">
        <div className={FormLabelClass}>{props.title}</div>
        <div className="flex gap-2">
            <input disabled={props.working} id={formId} type={props.inputType} pattern={props.pattern} className="flex-grow border w-full py-2 px-3 text-grey-darker" /><button type="button" onClick={addAction} className="btnbtn-sm">Add</button>
        </div>
        {ValidationErrorMessage({ property: props.property, errors: props.errors })}
        <div className="border h-32 overflow-y-scroll p-2">
            {empty && <div className="text-gray-200">(empty)</div>}
            {!empty && values.map((x, i) => <div className="flex" key={"item" + i}>
                <div className="flex-grow">{x}</div>
                <button disabled={props.working} type="button" onClick={() => deleteAction(x)} className="btn btn-sm">Delete</button>
            </div>)}

        </div>

    </div>;
}


export interface FormRowElements<T extends FieldValues> {
    TextBoxRow: (props: textBoxProps<T>) => ReactElement<any, any>,
    TimeSpanBoxRow: (props: textBoxProps<T>) => ReactElement<any, any>,
    TextAreaRow: (props: textAreaProps<T>) => ReactElement<any, any>,
    CheckBoxRow: (props: checkBoxRowProps) => ReactElement<any, any>,
    CheckBoxListRow: (props: checkBoxListProps<T>) => ReactElement<any, any>,
    SelectBoxRow: (props: selectBoxProps<T>) => ReactElement<any, any>,
    DataSelectBoxRow: (props: dataSelectBoxProps<T>) => ReactElement<any, any>,
    CheckBox: (props: formRowProps<T>) => ReactElement<any, any>,
    FileInputRow: (props: fileInputRowProps<T>) => ReactElement<any, any>,
    SaveButton: (props: { title?: string }) => JSX.Element,
    DatePickerRow: (props: formRowProps<T>) => ReactElement<any, any>,
}
//errors: DeepMap<T, FieldError>
export const formRows: <T extends FieldValues>(useForm: UseFormReturn<T>, working: boolean) => FormRowElements<T> = (useForm, working) => {
    //const inputClass = "border w-full py-2 px-3 text-grey-darker";
    return {
        TextBoxRow: (props) => {
            let placeholder = "";
            if (props.placeholder) {
                placeholder = props.placeholder;
            } else if (props.required) {
                placeholder = "required";
            } else {
                placeholder = "";
            }
            return (<FormGroupWithErrors key={props.key} rowProps={props} errors={useForm.formState.errors}>
                <input type={props.type ?? "text"} placeholder={placeholder} {...useForm.register(props.property, { required: props.required })} disabled={working} className="stdlib-textbox" />
            </FormGroupWithErrors>);
        },
        TimeSpanBoxRow: (props) => {
            let placeholder = "";
            if (props.placeholder) {
                placeholder = props.placeholder;
            } else if (props.required) {
                placeholder = "required";
            } else {
                placeholder = "";
            }
            return (<FormGroupWithErrors key={props.key} rowProps={props} errors={useForm.formState.errors}>
                <input type="time" pattern="[0-9]{2}:[0-9]{2}" placeholder={placeholder} {...useForm.register(props.property, { required: props.required })} disabled={working} className="stdlib-textarea" />
            </FormGroupWithErrors>);
        },
        TextAreaRow: (props) => {
            let placeholder = "";
            if (props.placeholder) {
                placeholder = props.placeholder;
            } else if (props.required) {
                placeholder = "required";
            } else {
                placeholder = "";
            }
            let heightClassName = " min-h-textarea-lg";
            if (props.height) {

                switch (props.height) {
                    case TextAreaHeight.h128:
                        heightClassName = " min-h-textarea-sm";
                        break;
                }
            }

            return (<FormGroupWithErrors key={props.key} rowProps={props} errors={useForm.formState.errors}>
                <textarea disabled={working} placeholder={placeholder} className={"stdlib-textarea" + heightClassName} {...useForm.register(props.property, { required: props.required })} />
            </FormGroupWithErrors>);
        },
        CheckBoxRow: (props) => {
            return (<FormGroup title={props.title} >
                {props.children}
            </FormGroup>);
        },
        CheckBoxListRow: (props) => {

            if (!useForm.clearErrors) {
                console.log("ERROR: CheckBoxListRow requires clearError be defined to function");
            }
            const children = props.options.map((x: any, i) => <label key={x.key ?? x.guid} className="stdlib-checkbox" >
                <input onClick={() => useForm.clearErrors?.(props.property)} disabled={working} value={x.key ?? x.guid} type="checkbox" {...useForm.register(((props.property as any) + "." + i) as any)} /> <span className="label">{x.value ?? x.name}</span></label>)
            return (<FormGroupWithErrors rowProps={props} errors={useForm.formState.errors}>
                <div className="flex flex-col gap-2">
                    {children}
                </div>
            </FormGroupWithErrors>);
        },
        SelectBoxRow: (props) => {
            return (<FormGroupWithErrors key={props.key} rowProps={props} errors={useForm.formState.errors}>
                <select className="stdlib-select" disabled={working} {...useForm.register(props.property, { required: props.required })} >
                    {props.options?.map((o: any) => <option key={o.key ?? o.guid} disabled={o.disabled} value={o.key ?? o.guid}>{o.value ?? o.name}</option>)}
                </select>
            </FormGroupWithErrors>);
        },
        DataSelectBoxRow: (props) => {


            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, setState] = useState<PageState>(PageState.NotLoaded);
            const [status, setStatus] = useState<PageStatus>(PageStatus.Idle);
            const [data, setData] = useState<NameGuidPair[] | KeyValuePair[]>();


            const paramValue = props.dataParam;
            const paramUrl = props.dataUrlFunc(paramValue);

            useEffect(() => {
                callGetApi(paramUrl, setState, setStatus, (data: ListContainer<NameGuidPair> | ListContainer<KeyValuePair>) => {
                    setData(data?.items);
                })
            }, [paramUrl])


            const disable = working || status !== PageStatus.Idle || !data;


            return (<FormGroupWithErrors key={props.key} rowProps={props} errors={useForm.formState.errors}>
                <><select className="stdlib-select" disabled={disable} {...useForm.register(props.property, { required: props.required })} >
                    {data?.map((o: any) => <option key={o.key ?? o.guid} value={o.key ?? o.guid}>{o.value ?? o.name}</option>)}
                    {!arrayNotNullOrEmpty(data) && <option disabled={true}>no options</option>}
                </select>
                    {status === PageStatus.Network && <div>Loading</div>}</>
            </FormGroupWithErrors>);
        },
        CheckBox: (props) => {
            return (<div key={props.key} className="block">
                <label className="stdlib-checkbox" ><input disabled={working} type="checkbox" {...useForm.register(props.property, { required: props.required })} /> <span className="label">{props.title}</span></label>
                {props.details && <div className={`ml-6 ${FormLabelClass}`}>{props.details}</div>}
                {ValidationErrorMessage({ property: props.property, errors: useForm.formState.errors })}
            </div>);
        },
        FileInputRow: (props) => {
            return (<FormGroupWithErrors key={props.key} rowProps={props} errors={useForm.formState.errors}>
                <input type="file" accept={props.accept} disabled={working} {...useForm.register(props.property, { required: props.required })} />
            </FormGroupWithErrors>);

        },
        SaveButton: (props) => <div className="flex pt-4 gap-4"><button disabled={working} className="button">{props.title ?? "Save"}</button> {working && <LoadingAnimation />}</div>,
        DatePickerRow: (props) => {
            const currentVals = useForm.watch();
            const currentD = currentVals[props.property] as Date;
            const value = currentD instanceof Date ? currentD : new Date();

            const handleChange = (selectedDate: Date) => {

                console.log("sevalu");
                console.log(props.property);
                console.log(selectedDate);

                useForm.setValue(props.property, selectedDate as any)
                console.log(selectedDate)
            }

            return (<FormGroupWithErrors key={props.key} rowProps={props} errors={useForm.formState.errors}>
                <Datepicker minDate={new Date()} dateFormat="yyyy-MM-dd" className="stdlib-textbox" onChange={handleChange} selected={value} />
            </FormGroupWithErrors>);
        },
    };


};


export function callGetApi<T>(apiUrl: string, setPageState: (state: PageState) => void, setPageStatus: (status: PageStatus) => void,
    setData: (data: T) => void, setErrors?: (errors: ObjectValidation<T>) => void) {//: ()=> void { //EffectCallback {//: undefined | (()=> void) {
    setPageStatus(PageStatus.Network);

    //const history = useHistory();
    axios.get<T>(apiUrl)
        .then((response) => {
            setData(response.data);
            setPageState(PageState.Loaded);
            setPageStatus(PageStatus.Idle);
        })
        .catch((err: { response: { status: number; data: ObjectValidation<T>; }; }) => {
            setPageStatus(PageStatus.Idle);
            const statusCode: number = err.response?.status ?? 500;
            if (statusCode === 400 && setErrors && err.response.data && err.response.data.kind === "object") {
                //const errArray = err.response.data as IValidationResult<T>;
                //errArray.errors.forEach(err => setError(err.name, { type: "server", message: err.message }));
                setErrors(err.response.data);
            } else if (statusCode === 404) {
                setPageState(PageState.NotFound);
            }
            else if (statusCode === 403) {
                setPageState(PageState.Forbidden);
            } else if (statusCode === 401) {
                //auth



                setPageState(PageState.NeedAuth);
            } else if (statusCode === 500) {
                setPageStatus(PageStatus.ServerError);
            } else {
                //connection
                setPageStatus(PageStatus.NetworkError);
            }
        });
}

interface setErrorOptions {
    type: string,
    message: string
}

export const checkBoxListDataPreProcess: (data: string[], options: Array<KeyValuePair>) => string[] = (data, options) => {
    //data = [a,d]
    //options= [a,b,c,d,e]
    if (!Array.isArray(data) || !Array.isArray(options)) return data;
    return options.map(x => data.includes(x.key) ? x.key : false) as any;
}

export const checkBoxListDataPostProcessToArray: (data: string[]) => string[] = (data) => {
    if (!Array.isArray(data)) return data;
    return data.filter(x => !!x);
}

export const checkBoxListDataPostProcessToString: (data: string[]) => string = (data) => {
    const options = checkBoxListDataPostProcessToArray(data);
    if (!options) return "";
    return options.join(";");
}


export function useErrorStore<T>(): {
    errors: ValidationMessage[],
    setError: (name: keyof T, options: setErrorOptions) => void,
    clearErrors: () => void,
    handlePostResponseErrors: (data: { errors?: ValidationMessage[] }) => void
} {
    const [errors, setErrorArray] = useState<ValidationMessage[]>([] as ValidationMessage[])
    const setError = useMemo(() => (name: keyof T, options: setErrorOptions) => {
        const newArray: ValidationMessage[] = [];
        if (errors) {
            errors.forEach(x => {
                if (x.name !== name) newArray.push(x);
            })
        }
        newArray.push({ name: name as string, message: options.message });
        setErrorArray(newArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setErrorArray]); //do not include errors here or useeffect will be called repeatedly
    const clearErrors = () => setErrorArray([]);
    const handlePostResponseErrors = (data: { errors?: ValidationMessage[] }) => {
        const newErrors: ValidationMessage[] = [];
        if (Array.isArray(data.errors)) {
            newErrors.push(...data.errors);
        }
        console.log("news");
        console.log(data);
        setErrorArray(newErrors);
    }
    return { errors, setError, clearErrors, handlePostResponseErrors };
}

export function callPostApi<T, T2>(apiUrl: string, model: T, setPageState: (state: PageState) => void,
    setPageStatus: (status: PageStatus) => void, setErrors: (errors: ObjectValidation<T>) => void,
    setData?: (data: T2) => void, clearErrors?: () => void) {
    setPageStatus(PageStatus.Network);
    if (clearErrors) {
        clearErrors();
    }
    axios.post(apiUrl, model)
        .then((response: { data: T2; }) => {
            setPageStatus(PageStatus.Idle);
            setPageState(PageState.Saved);
            if (setData) {
                setData(response.data);
            }
        })
        .catch((err: { response: { status: number; data: ObjectValidation<T>; }; }) => {
            setPageStatus(PageStatus.Idle);
            const statusCode: number = err.response?.status ?? 500;
            if (statusCode === 400 && err.response.data && err.response.data?.kind === "object") {
                //const errArray = err.response.data as IValidationResult<T>;
                //errArray.errors.forEach(err => setError(err.name, { type: "server", message: err.message }));
                setErrors(err.response.data);
            } else if (statusCode === 401) {
                setPageState(PageState.NeedAuth);
            } else if (statusCode === 403) {
                setPageState(PageState.Forbidden);
            } else if (statusCode === 404) {
                setPageState(PageState.NotFound)
            } else if (statusCode === 500) {
                setPageStatus(PageStatus.ServerError);
            } else {
                //other type of error
                setPageStatus(PageStatus.NetworkError);
            }
        });
}


export const copyNewErrorsToOldForm = <TModel extends FieldValues,>(setError: (name: keyof TModel, options: setErrorOptions) => void) => {
    return (errors: ObjectValidation<TModel>) => {
        for (const key in errors?.fields) {
            console.log("prop name " + key);
            const thisError = errors.fields[key];
            if (thisError?.kind !== "property") continue;
            setError((key as any), { type: "server", message: thisError.message });
        }
    }
}

export function friendlyFormatFromNowDate(date: string) {
    const mom = moment(date);
    return mom.fromNow();
}

export function friendlyFormatDateOnly(date: string) {
    const mom = moment(date);
    return mom.format("DD/MMM/YY");
}



export interface ReloadContainerChildProps {
    reloadSelf: () => void;
}


export interface ReloadContainerProps {
    children: React.FC<ReloadContainerChildProps>;
}

export function ReloadContainer(props: ReloadContainerProps) {
    const [reloadLevel, setReloadLevel] = useState<number>(0);
    const Child = props.children;
    return <Fragment >
        <Child reloadSelf={() => setReloadLevel(reloadLevel + 1)} key={reloadLevel} />
    </Fragment>;
}

export function findByGuid2(list: { [key: string]: string; } | undefined, guid: string) {
    if (!guid) return "(unknown)";
    if (!list || !list[guid]) return guid;
    return list[guid];
}


export const FormThumbnail = (imageUrl: string) => imageUrl ? <div><img src={imageUrl} alt="thumbnail" className="max-w-lg max-h-lg" /></div> : <div className="text-sm text-gray-600">(no thumbnail)</div>;