import { useEffect, useRef, useState } from "react";
import { useEffectOnce } from "./stdlib-useeffect";


interface PushRegistrationRequest {
    previousEndpointUrl?: string;
    endpointUrl: string;
    publicKey: string | undefined;
    authSecret: string | undefined;
    expirationTime: number | null;
}

const uploadRegistration = async (subscription: PushSubscription, apiUrl: string, previousEndpointUrl: string | undefined): Promise<number> => {

    var jsonModel: PushRegistrationRequest = {
        endpointUrl: subscription.endpoint,
        previousEndpointUrl: previousEndpointUrl,

        publicKey: arrayBufferToBase64url(subscription.getKey("p256dh")), //sub2?.keys?.p256dh,
        authSecret: arrayBufferToBase64url(subscription.getKey("auth")),
        expirationTime: subscription.expirationTime
    }

    //consoleLog("push registration request");
    //consoleLog(jsonModel);

    var result = await fetch(apiUrl, {
        method: 'post',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(jsonModel)
    });

    //consoleLog("push registration response");
    //consoleLog(result);

    return result.status;

}

// const consoleLog = (msg: any) => {
//     console.log(msg);
// }

const doPushRegister = async (opts: { registerApiUrl: string, pushKeyUrl: string, forceNewRegister?: boolean, onComplete: (result: { result: PushRegisterResult, errorMessage?: string }) => void }): Promise<void> => {
    //consoleLog("registering for push notifications");
    if (!("Notification" in window)) {
        opts.onComplete({ result: "unsupported", errorMessage: "This browser does not support notifications" });
    } else if (Notification.permission === "granted") {
        const registration = await navigator.serviceWorker.ready;

        const response = await fetch(opts.pushKeyUrl);

        // if (response.headers.get("content-type") !== "text/plain") {
        //     opts.onComplete({ result: "error", errorMessage: `Invalid content type for public key ${response.headers.get("content-type")}` });
        //     return;
        // }

        const vapidPublicKey = await response.text();
        if (!vapidPublicKey) {
            //consoleLog("No public key found");
            opts.onComplete({ result: "error", errorMessage: "No public key found" });
            return;
        }

        if (vapidPublicKey.length > 100) {
            opts.onComplete({ result: "error", errorMessage: "Public key too long" });
            return;

        }


        const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

        //consoleLog("has notification permission, get subscription");
        const subscription = await registration.pushManager.getSubscription();

        let oldEndpoint: string | undefined = undefined;// = subscription?.endpoint;

        let reregister = opts.forceNewRegister;

        if (subscription && !reregister) {
            //consoleLog("already registered");
            const uploadResult = await uploadRegistration(subscription, opts.registerApiUrl, undefined);

            if (uploadResult === 200) {
                opts.onComplete({ result: "ok" });
                return;
            } else if (uploadResult === 401) {
                opts.onComplete({ result: "unauth", errorMessage: "Unauthorized" });
                return;
            } else if (uploadResult === 410) {
                reregister = true;
            } else {
                opts.onComplete({ result: "error", errorMessage: `Unexpected status code result ${uploadResult}` });
                return;
            }

        }


        if (reregister && subscription) {
            oldEndpoint = subscription.endpoint;
            const unsub = await subscription.unsubscribe();
            if (!unsub) {
                //consoleLog("Failed to unsubscribe");
                opts.onComplete({ result: "error", errorMessage: "Failed to unsubscribe" });
                return;
            }
        }



        const newSubscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey
        });

        //consoleLog("received new subscription");




        if (!newSubscription?.endpoint) {
            //consoleLog("No endpoint found in new subscription");
            opts.onComplete({ result: "error", errorMessage: "No endpoint found in new subscription" });
            //opts.onComplete({ result: "error", errorMessage: JSON.stringify(newSubscription) });
            return;
        }
        uploadRegistration(newSubscription, opts.registerApiUrl, oldEndpoint);
    } else if (Notification.permission !== "denied") {
        //consoleLog("Notification permission not yet granted or denied");
        const permission = await Notification.requestPermission()

        if (permission !== "granted") {
            //consoleLog("Notification permission not granted");
            opts.onComplete({ result: "denied" });
            return;
        }
        //consoleLog("Permission granted do another pass to register");
        doPushRegister(opts);
    } else {
        //consoleLog("Permission already denied, not registering");
        opts.onComplete({ result: "denied" });
        return;
    }

};

function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    let binaryString = '';
    for (let i = 0; i < uint8Array.byteLength; i++) {
        binaryString += String.fromCharCode(uint8Array[i]);
    }
    return window.btoa(binaryString);
}

// For base64url (commonly used in web push):
function arrayBufferToBase64url(arrayBuffer: ArrayBuffer | null) {
    if (!arrayBuffer) return undefined;
    return arrayBufferToBase64(arrayBuffer)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

function urlBase64ToUint8Array(base64String: string) {
    //consoleLog("base64String");
    //consoleLog(base64String);
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export type PushRegisterResult = "ok" | "denied" | "unsupported" | "unauth" | "error" | "pending";

export const useAutoPushRegister = (register: boolean, pushKeyUrl: string, registerApiUrl: string) => {
    const [status, onComplete] = useState<PushRegisterResult>("pending");
    useEffectOnce(() => {
        if (!register) return;
        doPushRegister({ registerApiUrl, pushKeyUrl, onComplete: (result) => onComplete(result.result) });
    }, []);
    return { status };
}

export const useManualPushRegister = (pushKeyUrl: string, registerApiUrl: string) => {
    const [status, setStatus] = useState<PushRegisterResult>("pending");
    const [errorMessage, setErrorMessage] = useState<string>();
    const [working, setWorking] = useState<boolean>(false);

    const onComplete = (result: { result: PushRegisterResult, errorMessage?: string }) => {
        setErrorMessage(result.errorMessage);
        setStatus(result.result);
        setWorking(false);
    };
    return {
        onRegisterClick: () => {
            doPushRegister({ registerApiUrl, pushKeyUrl, onComplete });
        },
        status,
        errorMessage,
        working
    }


}


// export const usePushRegister = (register: boolean, pushKeyUrl: string, apiUrl: string, retryCount: number = 0) => {

//     const [result, setResultStatus] = useState<string>("");
//     const [registered, setRegistered] = useState<boolean>(false);

//     useEffect(() => {

//         if (!register) return;
//         console.log("registering for push notifications");

//         if (!("Notification" in window)) {
//             // Check if the browser supports notifications
//             //alert("This browser does not support desktop notification");
//             //console.log("This browser does not support desktop notification");
//         } else if (Notification.permission === "granted") {
//             navigator.serviceWorker.ready
//                 .then(function (registration) {
//                     console.log("do service work registration")
//                     // Use the PushManager to get the user's subscription to the push service.
//                     return registration.pushManager.getSubscription()
//                         .then(async function (subscription) {
//                             // If a subscription was found, return it.
//                             if (subscription) {
//                                 console.log("already registered");
//                                 return subscription;
//                             }

//                             // Get the server's public key
//                             const response = await fetch(pushKeyUrl);
//                             const vapidPublicKey = await response.text();
//                             // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
//                             // urlBase64ToUint8Array() is defined in /tools.js
//                             const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

//                             // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
//                             // send notifications that don't have a visible effect for the user).
//                             return registration.pushManager.subscribe({
//                                 userVisibleOnly: true,
//                                 applicationServerKey: convertedVapidKey
//                             });
//                         });
//                 }).then(function (subscription: any) {

//                     console.log("final result")
//                     console.log(subscription);
//                     // Send the subscription details to the server using the Fetch API.

//                     if (!subscription || !subscription.endpoint) return; //keys not returned after initial registration


//                     var jsonModel: PushRegistrationRequest = {
//                         endpointUrl: subscription.endpoint,
//                         publicKey: subscription?.keys?.p256dh,
//                         authSecret: subscription?.keys?.auth,
//                         expirationTime: subscription.expirationTime
//                     }

//                     console.log("push registration request", jsonModel);

//                     fetch(apiUrl, {
//                         method: 'post',
//                         headers: {
//                             'Content-type': 'application/json'
//                         },
//                         body: JSON.stringify(jsonModel)
//                     });

//                 });

//         } else if (Notification.permission !== "denied") {
//             console.log("Notification permission not granted");
//             // We need to ask the user for permission
//             Notification.requestPermission().then((permission) => {
//                 // If the user accepts, let's create a notification

//                 console.log("Notification permission requested");
//                 console.log(permission);
//                 // if (permission === "granted") {
//                 //     //const notification = new Notification("Hi there!");
//                 //     // …
//                 // }
//             });
//         } else {
//             console.log("Notification permission denied");
//         }
//     }, [retryCount]);

//     return { result, registered };

// }