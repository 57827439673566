export interface ActionBase {
    shouldCloseMenu?: boolean;
}

export interface ActionGap extends ActionBase {
    kind: "gap";
    gap: number;
    className?: string;
}

export interface ActionButton extends ActionBase {
    kind: "button";
    className?: string;
    title: string | JSX.Element,
    action?: () => void,
    disabled?: boolean,
    type?: "button" | "submit"
    formId?: string,
    customIcon?: JSX.Element,
    icon?: ActionIcon

}

export type ActionIcon = "refresh" | "new" | "check";

export interface ActionLink extends ActionBase {
    kind: "link";
    title: string;
    to: string;
    badge?: "new"
    className?: string;
    customIcon?: JSX.Element,
    icon?: ActionIcon

    //shouldCloseMenu?: boolean;
}

export interface ActionHref extends ActionBase {
    kind: "href";
    title: string,
    href: string
    target?: "_blank";
    className?: string;
    customIcon?: JSX.Element,
    icon?: ActionIcon
    download?: true;

    //shouldCloseMenu?: boolean;
}

export type ActionItem = ActionButton | ActionGap | ActionLink | ActionHref;

export const getActionIcon = (icon: ActionIcon, size: number) => {

    const className = `w-${size} h-${size}`;

    switch (icon) {

        case "new":
            return <svg key="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>;
        case "refresh":
            return <svg key="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        case "check":
            return <svg key="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width={1.5} stroke="currentColor" className={className}>
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>;
        default:
            return <></>;
    }



}