export const arrayNotNullOrEmpty = <T,>(array: T) => array && Array.isArray(array) && array.length > 0;

export const fancyTimeFormat = (duration?: number) => {
    // Hours, minutes and seconds

    if (!duration) return "0:0";

    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;

    return ret;
}