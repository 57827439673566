/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { bannerHelper, callGetApi, copyNewErrorsToOldForm, LoadingAnimation, PageState, PageStatus, useErrorStore } from "./stdlib-form";
import { FormLabelClass, TitleLabelClass } from "./stdlib-styles";
import { ActionIcon, ActionItem, getActionIcon } from "./stdlib-action";
import React from "react";

export interface PanelHeaderProps {
    title: string;
    disabled?: boolean;
    loading?: boolean;
    buttons?: ActionItem[] | undefined
    backButtonAction?: () => void
    backButtonVisible?: boolean
    backButtonIcon?: "back" | "close"
    content?: JSX.Element
}

interface RefreshButtonProps {
    showRefresh: boolean,
    onRefresh: () => void,
    refreshTitle: string,
    working: boolean
}


export interface DatePanelHeaderProps extends PanelHeaderProps, RefreshButtonProps {
    date: string;
    dateChange: (newDate: string) => void;
    showDateChangeButtons?: false,
}


export interface DatePanelHeaderPropsWithDateChange extends PanelHeaderProps, RefreshButtonProps {
    date: string;
    dateChange: (newDate: string) => void;
    showDateChangeButtons: true,
    onNextDate: () => void;
    nextDateDisabled: boolean,
    onPrevDate: () => void;
    prevDateDisabled: boolean,



}


const getBtnContent = (config: { icon?: ActionIcon, customIcon?: JSX.Element, title: string | JSX.Element }, size: number) => {
    const buttonContent: (JSX.Element | string)[] = [];
    const buttonClass: "btn btn-sm" | "btn btn-sm btn-icon" = config.icon || config.customIcon ? "btn btn-sm btn-icon" : "btn btn-sm";
    if (config.icon) {
        //standard cion
        buttonContent.push(getActionIcon(config.icon, size));
    } else if (config.customIcon) {
        //custom icon
        buttonContent.push(config.customIcon);
    }


    if (typeof config.title === "string") {
        buttonContent.push(<span key="text">{config.title}</span>);
    } else {
        buttonContent.push(<React.Fragment key={"nsc"}>{config.title}</React.Fragment>);
    }

    //buttonContent.push(config.title); 
    return { buttonClass, buttonContent };
}


export const RenderHeaderButtons = (props: { buttons: ActionItem[] | undefined }) => <>{props.buttons && props.buttons.map((x: ActionItem, i) => {
    if (x.kind === "button") {
        const { buttonClass, buttonContent } = getBtnContent(x, 4);
        return <button className={buttonClass} key={i} disabled={x.disabled} onClick={x.action} >{buttonContent.map((x, i) => <React.Fragment key={i}>{x}</React.Fragment>)}</button>;
    } else if (x.kind === "link") {
        const { buttonClass, buttonContent } = getBtnContent(x, 4);
        return <Link className={buttonClass} key={i} to={x.to}>{buttonContent.map(x => x)}</Link>
    } else if (x.kind === "href") {
        const { buttonClass, buttonContent } = getBtnContent(x, 4);
        return <a className={buttonClass} key={i} href={x.href} target={x.target} download={x.download}>{buttonContent.map(x => x)}</a>
    } else if (x.kind === "gap") {
        return <div key={i} className={`inline-block h-2 w-${x.gap}`}></div>;
    }
    //return <React.Fragment key={i}></React.Fragment>;
})} </>;


const backIcon = (props: PanelHeaderProps) => props.backButtonIcon === "close" ?
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>;



export const PanelHeader = (props: PanelHeaderProps) => <div className="flex-0 flex flex-row flex-wrap items-center justify-center md:justify-between gap-4 p-4">
    <div className="text-xl flex align-items-end">
        {props.backButtonVisible &&
            <button className="hover:text-gray-500" onClick={props.backButtonAction}>{backIcon(props)}</button>}
        <span className={TitleLabelClass}>{props.title}</span>
        {props.loading && <LoadingAnimation />}
    </div>

    <div className="flex flex-wrap gap-1 bg-white rounded p-2">
        <RenderHeaderButtons buttons={props.buttons} />
        {props.content && props.content}
    </div>
</div>;




export const DatePanelHeader = (props: DatePanelHeaderProps | DatePanelHeaderPropsWithDateChange) => <div className="flex-0 flex flex-row flex-wrap items-center justify-center md:justify-between gap-4 p-4">
    <div className="flex flex-col">
        <div className="text-xl flex align-items-end">
            {props.backButtonVisible &&
                <button className="hover:text-gray-500" onClick={props.backButtonAction}>{backIcon(props)}</button>}
            <span className={TitleLabelClass}>{props.title}</span>
            {props.loading && <LoadingAnimation />}
        </div>
        <div className="flex gap-2">
            <div>
                <input className="stdlib-textbox" type="date" value={props.date ?? ""} onChange={(e) => props.dateChange(e.target.value)} />
            </div>
            {props.showDateChangeButtons && <div>

                <button className="btn" onClick={props.onPrevDate} disabled={props.prevDateDisabled || props.working} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                </button>
                <button className="btn" onClick={props.onNextDate} disabled={props.nextDateDisabled || props.working} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>


                </button>
            </div>}
            {props.showRefresh && <div>
                <button className="btn flex flex-row items-center gap-1" onClick={props.onRefresh} disabled={props.working} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                    <span>{props.refreshTitle}</span></button>
            </div>}

        </div>
    </div>


    {(props.buttons || props.content) && <div className="flex flex-wrap gap-1 bg-white rounded p-2">
        <RenderHeaderButtons buttons={props.buttons} />
        {props.content && props.content}
    </div>}
</div>;


export interface PanelFooterProps {
    okTitle?: string;
    okAction?: () => void;
    okHidden?: boolean;
    okFormId?: string;
    okDisabled?: boolean;

    cancelTitle?: string,
    cancelAction?: () => void,
    cancelHidden?: boolean,

    working?: boolean
}

export const PanelFooter = (props: PanelFooterProps) => {


    const actionItems: ActionItem[] = [];

    if (!props.cancelHidden) {
        if (props.cancelAction) {
            actionItems.push({
                kind: "button",
                title: props.cancelTitle ?? "Cancel",
                action: props.cancelAction,
                disabled: props.working,
                className: "btn"
            })
        }
    }

    if (!props.okHidden) {
        if (props.okAction) {
            actionItems.push({
                kind: "button",
                title: props.okTitle ?? "Ok",
                action: props.okAction,
                disabled: props.working || props.okDisabled,
                className: "btn btn-primary"
            })
        } else if (props.okFormId) {
            actionItems.push({
                kind: "button",
                title: props.okTitle ?? "Ok",
                type: "submit",
                formId: props.okFormId,
                disabled: props.working || props.okDisabled,
                className: "btn btn-primary"
            })

        }
    }



    return <div className="flex-grow-0 flex gap-2 justify-end p-4">
        {RenderActionItems(actionItems)}
    </div>
};



export interface PageLayoutProps {
    title: string;
    children: Array<JSX.Element | JSX.Element[] | string> | JSX.Element | string
    modals?: JSX.Element | Array<JSX.Element>;
    footer?: PanelFooterProps;
    backButtonAction?: () => void
    backButtonVisible?: boolean
    backButtonIcon?: "back" | "close"
    headerButtons?: ActionItem[] | undefined

}

export enum PanelWidth { Narrow, Wide, Grow }

export const WidthEnumToClass: (width: PanelWidth | undefined) => string = (width) => {
    if (!width) return "max-w-xl";
    const w = width as PanelWidth;
    switch (w) {
        case PanelWidth.Narrow:
            return "lg:flex-grow-0 lg:w-80";
        case PanelWidth.Wide:
            return "lg:flex-grow-0 lg:w-screen-1/2";
        case PanelWidth.Grow:
            return "flex-1";

        default:
            return "max-w-xl";
    }
}


export const PanelBackgroundStyle = "bg-white flex-1 border border-gray-300 md:m-4";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PanelBackground = (props: { children: any, width?: PanelWidth, className?: string }) => {

    const widthClass = WidthEnumToClass(props.width);

    return <div className={`bg-white flex-grow border border-gray-300 md:m-4 flex flex-col min-h-0 min-w-0 ${widthClass} ${props.className}`} >
        {props.children}
    </div >;
}

export const PanelLayout = (props: PageLayoutProps) => <>
    <PanelHeader backButtonAction={props.backButtonAction} backButtonIcon={props.backButtonIcon} backButtonVisible={props.backButtonVisible} title={props.title} buttons={props.headerButtons} />
    <div className="flex-grow overflow-y-scroll flex flex-col">
        <div className="p-4 flex flex-col flex-1">
            {props.children}
        </div>
    </div>
    {props.modals}
    {props.footer && <PanelFooter {...props.footer} />}

</>;

export const PanelScrollContainer = (props: { children: any }) => <div className="flex-grow overflow-y-scroll overflow-x-hidden flex flex-col p-4" >{props.children}</div>;

export interface LoadContainerProps<TDataProps, TChildProps> {
    dataUrl: string | undefined,
    childProps: TChildProps,
    children: React.FC<{ data: TDataProps } & TChildProps>
    reloadCount?: number,
    errorView?: (statusBanner: JSX.Element) => JSX.Element
}

export function LoadContainer<TDataProps, TChildProps>(props: LoadContainerProps<TDataProps, TChildProps>) {
    const [state, setState] = useState<PageState>(PageState.NotLoaded);
    const [status, setStatus] = useState<PageStatus>(PageStatus.Idle);
    const [data, setData] = useState<TDataProps>();
    const { setError, errors } = useErrorStore<TDataProps>();
    //const [reloadLevel, setReloadLevel] = useState<number>(props.)
    const { statusBanner, contentNotLoaded } = bannerHelper(state, status);

    const defaultView = <></>;

    useEffect(() => {
        if (!props.dataUrl) return;
        //console.log("lc load: " + props.reloadCount + " " + props.dataUrl);
        callGetApi<TDataProps>(props.dataUrl, setState, setStatus, setData, copyNewErrorsToOldForm(setError as any))
    }, [props.dataUrl, props.reloadCount]);


    if (!props.dataUrl) return defaultView;

    if (contentNotLoaded()) {
        return props.errorView ? props.errorView(statusBanner(errors)) : statusBanner(errors);
        //return statusBanner(errors);
    }


    if (!data) return defaultView;

    return <props.children data={data} {...props.childProps} />
}

export interface LoadContainer2Props<TDataProps> {
    dataUrl: string | undefined,
    //childProps: TChildProps,
    children: React.FC<{ data: TDataProps }>
    reloadCount?: number,
    errorView?: (statusBanner: JSX.Element) => JSX.Element
}

export function LoadContainer2<TDataProps,>(props: LoadContainer2Props<TDataProps>) {
    const [state, setState] = useState<PageState>(PageState.NotLoaded);
    const [status, setStatus] = useState<PageStatus>(PageStatus.Idle);
    const [data, setData] = useState<TDataProps>();
    const { setError, errors } = useErrorStore<TDataProps>();
    //const [reloadLevel, setReloadLevel] = useState<number>(props.)
    const { statusBanner, contentNotLoaded } = bannerHelper(state, status);

    const defaultView = <></>;

    useEffect(() => {
        if (!props.dataUrl) return;
        //console.log("lc load: " + props.reloadCount + " " + props.dataUrl);
        callGetApi<TDataProps>(props.dataUrl, setState, setStatus, setData, copyNewErrorsToOldForm(setError as any))
    }, [props.dataUrl, props.reloadCount]);


    if (!props.dataUrl) return defaultView;

    if (contentNotLoaded()) {
        return props.errorView ? props.errorView(statusBanner(errors)) : statusBanner(errors);
        //return statusBanner(errors);
    }


    if (!data) return defaultView;

    return <props.children data={data} />
}

export const PriceTable = (props: { title?: string, total?: number, totalDescription?: string, entries?: { description: string, amount: number, negative?: boolean }[], hideZeroEntries?: boolean, hideIfAllZeros?: boolean }) => {

    if (props.hideIfAllZeros && !props.total) {
        if (!props.entries || !Array.isArray(props.entries)) return <></>;
        if (!props.entries.some((x) => x.amount > 0)) return <></>;
    }

    if (props.total === 0 && !props.entries && props.hideIfAllZeros) {
        return <></>;
    }

    const filteredEntries = props.hideZeroEntries ? props.entries?.filter(x => x.amount > 0) : props.entries;
    return <div className="flex flex-col text-xs gap-2">
        {props.title && <div className={FormLabelClass + " "}>{props.title}</div>}
        <div className="border">
            {filteredEntries && filteredEntries.map((x, i) =>
                <div className="flex gap-2" key={i}>
                    <div className="flex-1">{x.description}</div>
                    <div className="flex-0">{x.negative && '-'}${x.amount.toFixed(2)}</div>
                </div>
            )}
            {props.total && props.total > 0 &&
                <div className="flex gap-2 font-bold border-t border-b ">
                    <div className="flex-1">{props.totalDescription ?? "Total"}</div>
                    <div className="flex-0">${props.total.toFixed(2)}</div>
                </div>}
        </div>
    </div>;
}

export interface FormTextRowProps {
    title: string;
    message: string | number | string[] | JSX.Element | JSX.Element[];
    hideOnEmptyMessage?: boolean;
    defaultMessage?: string;
    details?: string;
    key?: any
    format?: "text" | "email" | "phone",
    editButtonAction?: () => void | undefined,
    editButtonTitle?: string,
    editButtonStyle?: string,

}

export const FormTextRow2 = (props: FormTextRowProps) => {
    if (props.hideOnEmptyMessage && !props.message) {
        return <></>;
    }


    let messageContent: any = props.message;
    let flexDir = "";
    if (Array.isArray(props.message)) {
        messageContent = <div className="flex flex-col">{props.message.map((x, i) => <div key={i}>{x}</div>)}</div>
        flexDir = "flex-col-reverse"
    } else if (props.message && props.format) {
        switch (props.format) {
            case "email":
                messageContent = <a className="link" href={`mailto:${props.message}`}>{props.message}</a>;
                break
            case "phone":
                messageContent = <a className="link" href={`tel:${props.message}`}>{props.message}</a>;
                break
        }
    } else if (!props.message && props.defaultMessage) {
        messageContent = props.defaultMessage;
    }



    return <div key={props.key} className="flex justify-between flex-wrap">
        <div className={`flex-0 ${FormLabelClass} `}>
            {props.title}
        </div>
        <div className="flex-0 flex flex-col gap-1 items-end">
            <div className={`flex gap-1 ${flexDir}`}>
                <span className="ml-2">{messageContent}</span>
                {props.editButtonAction && <button className={props.editButtonStyle ?? "btn btn-xs"} onClick={props.editButtonAction}>{props.editButtonTitle ?? "Edit"}</button>}
            </div>
            {props.details && <span className="text-xs">{props.details}</span>}
        </div>
    </div>;
};


export interface FormLogRowProps<T> {
    title: string,
    items: T[],
    row: (item: T, index: number) => JSX.Element,
    hideIfEmpty?: boolean,
}

export const FormLogRow: <T>(props: FormLogRowProps<T>) => JSX.Element = props => {
    if ((!props.items || props.items.length === 0) && props.hideIfEmpty) {
        return <></>;
    }

    return <div className="flex flex-col">
        <div className={`flex-0 ${FormLabelClass} mb-2`}>
            {props.title}
        </div>
        <div className="flex-0 flex flex-col">
            {!props.items && <div className="text-gray-800 text-sm">(nothing to show)</div>}
            {props.items && props.items.map((x, i) => props.row(x, i))}
        </div>
    </div>;

}

export const ButtonGroup: (props: { children: any }) => JSX.Element = (props) => <div className="flex flex-row flex-wrap gap-2">{props.children}</div>


export const alertStyle = "rounded-sm flex flex-wrap md:flex-nowrap gap-2 p-2 bg-gray-100 justify-between";
export const actionButtonClass = "btn btn-lg whitespace-nowrap";
export const statusItem = (title: string, details: string, buttonTitle?: string, buttonAction?: () => void) => <div className={`${alertStyle} items-center`}>
    <div className="flex flex-col gap-2" >
        <div className={`${TitleLabelClass} text-gray-700`}>{title}</div>
        {details && <div className={`${FormLabelClass} text-gray-700`}>{details}</div>}
    </div>
    {buttonTitle && <div className="md:flex-0">
        <button className={actionButtonClass} onClick={buttonAction} >{buttonTitle}</button>
    </div>}
</div>

export interface ActionItemRenderOptions {
    buttonClassName?: string;
    linkActiveClassName?: string;
    linkClassName?: string;
    hrefClassName?: string;
    shouldCloseMenu?: boolean;
}

export type ActionItemClickHandler = (item: ActionItem) => void


export const RenderActionItem: (item: ActionItem, key: number, options?: ActionItemRenderOptions, onClick?: ActionItemClickHandler) => JSX.Element = (item, key, options, onClick) => {



    if (item.kind === "link") {


        return <NavLink onClick={() => onClick && onClick(item)} key={key} activeClassName={item.className ?? options?.linkActiveClassName} className={options?.linkClassName} to={item.to}><div>{item.title}{item.badge === "new" && <div>NEW</div>}</div></NavLink>
    }

    if (item.kind === "button") {
        const btnc = getBtnContent(item, 4);
        return <button key={key} type={item.type ?? "button"} form={item.formId} className={btnc.buttonClass} onClick={() => {
            if (onClick) onClick(item);
            if (item.action) item.action();
        }} disabled={item.disabled}>{btnc.buttonContent}</button>
    }

    if (item.kind === "href") {
        return <a key={key} className={item.className ?? options?.hrefClassName} href={item.href}>{item.title}</a>
    }


    return <></>;
}

export const RenderActionItems: (items: ActionItem[], options?: ActionItemRenderOptions, onClick?: ActionItemClickHandler) => JSX.Element = (items, options, onClick) => {
    return <>
        {Array.isArray(items) && items.map((x, i) => RenderActionItem(x, i, options, onClick))}
    </>;
}

export const EmptyPage = () => {
    return (<div className="text-xl text-center text-gray-400 p-8">Choose a page from the menu</div>);
}

export const EmptyView = (props: { message?: string }) => <div>

    <div className="text-gray-400 flex gap-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
        </svg>

        <span className="text-xl">{props?.message ?? "No results found"}</span>
    </div>
</div>