import { Suspense, startTransition, useEffect, useState } from "react";
import { NavLink, BrowserRouter as Router, Switch, Route, RouteProps, useHistory, Redirect } from "react-router-dom";//
import { Helmet, HelmetProvider } from "react-helmet-async";
import { LoadingView, PageState, PageStatus, ValidationMessage, bannerHelper, callGetApi } from "./stdlib-form";
import { PortalPageDetails, PortalRouterPageObj, AppMessage, AppRedirectAction } from "./stdlib-appmodels";
import { ActionItemClickHandler, ActionItemRenderOptions, RenderActionItems } from "./stdlib-ui";
import toast, { Toaster } from "react-hot-toast";
import CaicosConsultingLogo from "./footer-logo.png";
import React from "react";

import { AuthCheckTotpPath, AuthForgotPasswordPath, AuthResetPasswordPath, AuthSetupTotpPath, AuthSignInPath, AuthSuccessRedirectUrl } from "./auth/auth-paths";
import { AuthPageProps } from "./auth/stdlib-auth-shared";
import NotFoundPage from "./stdlib-notfoundpage";
import { ErrorBoundary, CrashPage, AppMessagePage } from "./stdlib-crashpage";
import { useAutoPushRegister } from "./stdlib-push";
//import page from "../pages/configuration/StaffUserPage";
//import { ErrorBoundary } from "react-error-boundary";


export const combinePageDetails: (base: PortalPageDetails, child: PortalPageDetails) => PortalPageDetails = (base, child) => {
	return {
		...base,
		child: child,
	};
};

export interface RedirectHandlerOptions {
	parent: (redirect: AppRedirectAction) => void;
	parentUrl?: string;
	refreshChildFunc?: () => void;
}

const SignInPage = React.lazy(() => import("./auth/stdlib-auth-signin"));
const ForgotPasswordPage = React.lazy(() => import("./auth/stdlib-auth-forgot-password"));
const ResetPasswordPage = React.lazy(() => import("./auth/stdlib-auth-reset-password"));
const SetupTotpPage = React.lazy(() => import("./auth/stdlib-auth-totp-setup"));
const CheckTotpPage = React.lazy(() => import("./auth/stdlib-auth-totp-check"));

//const CrashPage = React.lazy(() => import("./stdlib-crashpage"));



const renderArray: <T>(array: T[] | undefined) => boolean = (array) => Array.isArray(array) && array.length > 0;

const renderBar: (details: PortalPageDetails) => boolean = (details) => renderArray(details.actions);

const renderOptions: ActionItemRenderOptions = {
	linkActiveClassName: "active",
	linkClassName: "sidebar-button",
	buttonClassName: "border text-gray-500 p-1 m-1 rounded text-sm hover:bg-gray-200",
	hrefClassName: "p-1 m-1 ml-3 link text-sm",
};

const MainMenu = <T,>(props: { appData: T; appProps: AppProps<T>; pageDetails: PortalPageDetails; setMenuOpen: (open: boolean) => void; lockMenu: boolean; setLockMenu: (lock: boolean) => void }) => {
	const onClick: ActionItemClickHandler = (item) => {
		if (item.shouldCloseMenu) props.setMenuOpen(false);
	};

	const drawPageDetails: (index: number, details: PortalPageDetails, onClick: ActionItemClickHandler) => JSX.Element = (index, details, onClick) => {
		const isLastChild = !details.child?.actions;
		const lastId = isLastChild ? "menu-last-item" : "";
		//console.log(details);
		return (
			<>
				{" "}
				{renderBar(details) && (
					<div className="flex flex-col" {...(lastId && { id: lastId })}>
						<div className="text-gray-500 p-1 text-sm m-1 font-bold">{details.title}</div>
						{details.actions && <div className="flex flex-col">{RenderActionItems(details.actions, renderOptions, onClick)}</div>}
					</div>
				)}
				{details.child && drawPageDetails(index + 1, details.child, onClick)}
			</>
		);
	};

	return (
		<div className="stdlib-menu">
			<div className="flex-grow flex flex-row flex-wrap overflow-y-auto">
				<div className="flex flex-col">
					{props.appProps.routes
						.filter((x) => props.appProps.routeFilterFunc(props.appData, x))
						.map((x, i) => (
							<NavLink onClick={() => x.shouldCloseMenu && props.setMenuOpen(false)} exact={x.exact} key={i} activeClassName="active" className="sidebar-button" to={x.path}>
								<div>{x.name}</div>
							</NavLink>
						))}
				</div>
				{props.pageDetails && drawPageDetails(0, props.pageDetails, onClick)}
			</div>
			<div className="flex flex-col p-2 gap-2 ">
				<div className="text-xs text-ellipsis whitespace-nowrap  w-32 overflow-hidden">{(props.appData as any)?.name}</div>
				<div>
					<button className="btn stdlib-header-button" onClick={() => props.setLockMenu(!props.lockMenu)}>
						{props.lockMenu && (
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
								/>
							</svg>
						)}
						{!props.lockMenu && (
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
								/>
							</svg>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

const MainContent = <T,>(props: { registerForPush: boolean, pushKeyUrl: string | undefined, pushRegisterApiUrl: string | undefined, appData: T; appProps: AppProps<T> }) => {
	const history = useHistory();

	const lockMenuKey = "lock-menu";
	const lockedByDefault = () => {
		let value = window.innerWidth > 1000;
		const lsv = localStorage.getItem(lockMenuKey);

		if (lsv !== null) {
			value = lsv === "yes";
		}
		return value;
	};

	const [menuOpenState, setMenuOpen] = useState<boolean>(lockedByDefault);
	const [menuLocked, setMenuLockedState] = useState<boolean>(lockedByDefault);
	const [pageDetails, setPageDetailsState] = useState<PortalPageDetails>({} as PortalPageDetails);
	const [redirectUrl, setRedirectUrl] = useState<string>();
	const [redirectUrlCount, setRedirectUrlCount] = useState<number>(0);

	useAutoPushRegister(props.registerForPush ?? false, props.pushKeyUrl!, props.pushRegisterApiUrl!);

	const menuOpen = menuLocked || menuOpenState;
	const showToast = (appMsg: AppMessage) => toast(appMsg.message);

	const setPageDetails = (details: PortalPageDetails) => {
		if (JSON.stringify(details) === JSON.stringify(pageDetails)) return;
		setPageDetailsState(details);
	};

	const setMenuLocked = (value: boolean) => {
		localStorage.setItem(lockMenuKey, value ? "yes" : "no");
		setMenuLockedState(value);
	};

	useEffect(() => {
		const element = document.getElementById("menu-last-item");
		if (!element) return;
		const htmlElement = element as HTMLElement;
		htmlElement.scrollIntoView();
	}, [menuOpen, pageDetails]);

	useEffect(() => {
		if (!redirectUrl) return;
		history.push(redirectUrl);
	}, [redirectUrl, redirectUrlCount]);

	const doMenuClose = (open: boolean) => {
		if (menuLocked) return;
		setMenuOpen(open);
	};

	const doRedirect = (redirect: AppRedirectAction) => {
		//console.log("root redirect");
		if (redirect.toast) showToast(redirect.toast);
		if (redirect.redirectAction !== "redirect" || !redirect.redirectUrl) {
			console.log(`Cannot redirect because redirectAction not valid here or '${redirect.redirectUrl}' is empty`);
			return;
		}
		setMenuOpen(false);
		setRedirectUrl(redirect.redirectUrl);
		setRedirectUrlCount(redirectUrlCount + 1);
	};
	//{mainMenu(props.appProps, props.appData)}
	return (
		<div className="flex flex-col flex-1 min-w-0 min-h-0 h-dscreen overflow-clip bg-stdlib-panel-bg">
			<div className="stdlib-header">
				{!menuLocked && (
					<button className="btn stdlib-lock-button" onClick={() => setMenuOpen(!menuOpen)}>
						{menuOpen && (
							<svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
							</svg>
						)}

						{!menuOpen && (
							<svg fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						)}
					</button>
				)}
				<img className="h-8" src={props.appProps.headerLogo} alt="Portal" />
			</div>
			<div className="flex flex-1 min-w-0 min-h-0 flex-col lg:flex-row overflow-clip">
				{menuOpen && <MainMenu {...props} pageDetails={pageDetails} setMenuOpen={doMenuClose} lockMenu={menuLocked} setLockMenu={setMenuLocked} />}
				<div className="flex flex-1 justify-center overflow-clip stdlib-background min-w-0 min-h-0">
					<Suspense fallback={LoadingView}>
						<Switch>
							{props.appProps.routes.map((route, idx) => {
								return (
									route.component && (
										<Route
											key={idx}
											path={route.path}
											exact={route.exact}
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											render={(p: any) => <route.component {...p} setPageDetails={setPageDetails} showToast={showToast} appData={props.appData} doRedirect={doRedirect} />}
										/>
									)
								);
							})}
							<Route path="*" render={() => <NotFoundPage setPageDetails={setPageDetails} showToast={showToast} appData={props.appData} doRedirect={doRedirect} />} />
						</Switch>
					</Suspense>
				</div>
			</div>
			<div className="stdlib-footer">
				&copy; {props.appProps.footerCompany} - all rights reserved. Website by{" "}
				<a href="https://caicos.consulting" target="_blank" rel="referrer" className="font-semibold hover:underline">
					<img className="h-3 w-3 inline mr-1" src={CaicosConsultingLogo} alt="Caicos Consulting" />
					Caicos Consulting
				</a>
				.
			</div>
		</div>
	);
};

const appLoadingView = <div style={{
	height: "100dvh",
	width: "100dvw",
	backgroundColor: "white",
	display: "flex",
	flexDirection: "column",
	fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',	Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",

}}>


	<div style={{
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		lineHeight: "20px",
		flexDirection: "column",
		flex: "1 1 0%"
	}}>




		<div style={{ display: "flex", alignItems: "start", gap: "12px", justifyContent: "baseline", lineHeight: "20px" }}>
			<div style={{ display: "flex", flexDirection: "column", alignItems: "start", gap: "12px", justifyContent: "baseline", lineHeight: "20px" }}>
				<div>
					<img src="/loading.gif" style={{ height: "128px", width: "128px", marginLeft: "20px" }} />
				</div>
				<div style={{ display: "flex", alignItems: "start", gap: "12px", justifyContent: "baseline", lineHeight: "20px" }}>
					<span style={{ fontSize: "20px", color: "#374151" }}>loading account</span>
				</div>
			</div>
		</div>




	</div>

	<div style={{ flex: "none", padding: "20px", display: "flex", justifyContent: "center" }}>
		<img src="/preload-cc-logo.png" alt="Site by Caicos Consulting" />
	</div>



</div>

const appErrorView = (pageState: PageState, pageStatus: PageStatus) => {

	let content: JSX.Element[] = [];



	if (pageStatus === PageStatus.NetworkError) {
		content = [
			<span key="neterr">Network error</span>

		];
	} else if (pageStatus === PageStatus.ServerError) {
		content = [
			<span key="serverr">Server error</span>,
			<div key="servdet" className="bg-gray-200 text-sm text-gray-900 font-serif p-2">
				<span>Server error processing app load request</span>
			</div>


		];

	} else {
		content = [
			<span key="otherr">Other error</span>,

		];

	}



	return <AppMessagePage key="crash" showReloadButton={true}>

		{content}
	</AppMessagePage>

}
export interface AppProps<TAppData> {
	appDataApiUrl: string;
	//appObject: TAppObj;
	headerLogo: string;
	defaultTitle: string;
	authLogoUrl?: string;
	authBackgroundUrl?: string;
	registerForPush?: boolean;
	pushKeyUrl?: string;
	pushRegisterApiUrl?: string;
	footerCompany: string;

	// eslint-disable-next-line @typescript-eslint/ban-types
	routes: PortalRouterPageObj<TAppData, {}, {}>[];
	// eslint-disable-next-line @typescript-eslint/ban-types
	routeFilterFunc: (appData: TAppData, route: PortalRouterPageObj<TAppData, {}, {}>) => boolean;
}





export const stdlibApp = <T,>(props: AppProps<T>) => {
	return () => {
		const [reloadCount, setReloadCount] = useState<number>(0);
		const [pageState, setPageState] = useState<PageState>(PageState.NotLoaded);
		const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Idle);
		const [appData, setAppData] = useState<T>({} as T);
		const { statusBanner, contentNotLoaded } = bannerHelper(pageState, pageStatus);

		//




		useEffect(() => callGetApi<T>(props.appDataApiUrl, setPageState, setPageStatus, setAppData), [reloadCount]);
		const contentLoaded = !contentNotLoaded();

		let mainContent: JSX.Element;
		if (pageState === PageState.NeedAuth) {
			mainContent = <Redirect to={AuthSignInPath} />;
		} else if (!contentLoaded) {

			if (pageStatus === PageStatus.Network) {
				mainContent = appLoadingView;
			} else {
				mainContent = appErrorView(pageState, pageStatus);
			}

			//console.log(pageState, pageStatus)

			//mainContent = appLoadingView;
			//mainContent = statusBanner();

			//need loading page and error page views


		} else {
			mainContent = <MainContent pushKeyUrl={props.pushKeyUrl} pushRegisterApiUrl={props.pushRegisterApiUrl} registerForPush={props.registerForPush ?? false} appData={appData} appProps={props} />;
		}

		const refreshAuthStatus = () => {
			setPageState(PageState.NotLoaded);
			setPageStatus(PageStatus.Idle);
			setReloadCount(reloadCount + 1);
		};


		const authPageProps: AuthPageProps = {
			refreshAuthStatus,
			portalTitle: props.defaultTitle,
			backgroundImageUrl: props.authBackgroundUrl,
			logoUrl: props.authLogoUrl
		}

		return <HelmetProvider>
			<Router >
				<Helmet>
					<title>{props.defaultTitle}</title>
				</Helmet>
				<Toaster />
				<Suspense>
					<ErrorBoundary fallback={CrashPage}>
						<Switch>
							<Route exact path={AuthSignInPath} render={() => <SignInPage {...authPageProps} />} />
							<Route exact path={AuthSetupTotpPath} render={() => <SetupTotpPage {...authPageProps} />} />
							<Route exact path={AuthCheckTotpPath} render={() => <CheckTotpPage {...authPageProps} />} />
							<Route exact path={AuthForgotPasswordPath} render={() => <ForgotPasswordPage {...authPageProps} />} />
							<Route path={AuthResetPasswordPath} render={() => <ResetPasswordPage {...authPageProps} />} />
							<Route path="*" render={() => mainContent} />
						</Switch>
					</ErrorBoundary>
				</Suspense>

			</Router>
		</HelmetProvider>;
	};
};

