import React from "react";



export class ErrorBoundary extends React.Component<{ children: JSX.Element, fallback: JSX.Element }, { hasError: boolean }, {}> {
    constructor(props: { children: JSX.Element, fallback: JSX.Element }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, info: any) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        //logErrorToMyService(error, info.componentStack);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback;
        }

        return this.props.children;
    }
}



export const AppMessagePage = (props: { children: JSX.Element[] | JSX.Element, showDashboardButton?: boolean, showReloadButton?: boolean }) => <div className="flex-1 bg-gray-100 flex flex-col justify-center items-center">



    <div className="max-w-md flex flex-col gap-4 bg-white p-4 rounded-md drop-shadow-md">
        <div className="flex flex-col gap-2 text-gray-900">
            {props.children}
        </div>


        {props.showDashboardButton || props.showReloadButton &&

            <div className="flex flex-row gap-4 items-center justify-center">
                {props.showReloadButton &&
                    <button className="btn btn-lg btn-primary" onClick={() => { window.location.reload() }}>Reload Page</button>}
                {props.showDashboardButton && <button className="btn btn-lg" onClick={() => { window.location.href = "/" }}>Return to Dashboard</button>}
            </div>}

    </div>
</div>;

export const CrashPage = <div className="flex-1 bg-gray-100 flex flex-col justify-center items-center">



    <div className="max-w-md flex flex-col gap-4 bg-white p-4 rounded-md drop-shadow-md">
        <div className="flex flex-col gap-2 text-gray-900">
            <h1 className="text-xl">Something went wrong</h1>
            <p>Sorry, this webapp crashed. Click Reload Page to refresh this page.</p>
            <p>If you immediately see this error again, return to the Dashboard.</p>
        </div>



        <div className="flex flex-row gap-4 items-center justify-center">
            <button className="btn btn-lg btn-primary" onClick={() => { window.location.reload() }}>Reload Page</button>
            <button className="btn btn-lg" onClick={() => { window.location.href = "/" }}>Return to Dashboard</button>
        </div>

    </div>
</div>;

//}

//export default CrashPage;